import './stats.scss';

import Stats1 from './stats-1';
import Stats2 from './stats-2';
import Stats3 from './stats-3';
import Stats4 from './stats-4';
import Stats5 from './stats-5';
import Stats6 from './stats-6';
import { FunctionComponent } from 'react';
import ContractsNew from './contractsNew';
import UpgradesNew from './upgradesNew';

export const Stats: FunctionComponent = () => {
  return (
    <div className="stats1_wrap">
      <div className="stats1">
        <div className="stats1_item">
          <div className="stats1_item_icon">
            <Stats1 />
          </div>
          <div className="stats1_item_content">
            <div className="stats1_item_content_title">13 038 558</div>
            <div className="stats1_item_content_label">Пользователи</div>
          </div>
        </div>
        <div className="stats1_item">
          <div className="stats1_item_icon">
            <ContractsNew />
          </div>
          <div className="stats1_item_content">
            <div className="stats1_item_content_title">303 110</div>
            <div className="stats1_item_content_label">Контракты</div>
          </div>
        </div>
        <div className="stats1_item">
          <div className="stats1_item_icon">
            <UpgradesNew />
          </div>
          <div className="stats1_item_content">
            <div className="stats1_item_content_title">17 625 945</div>
            <div className="stats1_item_content_label">Апгрейды</div>
          </div>
        </div>
        <div className="stats1_item">
          <div className="stats1_item_icon">
            <Stats6 />
          </div>
          <div className="stats1_item_content">
            <div className="stats1_item_content_title">429 347 215</div>
            <div className="stats1_item_content_label">Открытые кейсы</div>
          </div>
        </div>
      </div>
    </div>
  );
};
